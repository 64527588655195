import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    background: "#0099FF",
})

export function withRotate(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 90 }}
                transition={{ duration: 2 }}
            />
        )
    }
}

export function withHover(Component): ComponentType {
    return (props) => {
        return <Component {...props} whileHover={{ scale: 1.05 }} />
    }
}

export function withRandomColor(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                animate={{
                    background: store.background,
                }}
                onClick={() => {
                    setStore({ background: randomColor() })
                }}
            />
        )
    }
}

import type { ComponentType } from "react"
import { useEffect, useState } from "react"

const CAPTION_SIZE_FACTOR = 0.85

export const withCaptionAlignCenter = (Component): ComponentType => {
    return (props: any) => {
        const [originalFontSize, setOriginalFontSize] = useState(16)

        useEffect(() => {
            const elements = document.querySelectorAll(
                `.${props.className} .framer-image, .${props.className} .framer-text-module`
            )

            setOriginalFontSize(
                parseFloat(
                    window.getComputedStyle(elements[0].nextElementSibling)
                        .fontSize
                )
            )

            elements.forEach((imageElement) => {
                const nextElement = imageElement.nextElementSibling

                if (
                    nextElement.tagName &&
                    nextElement.tagName.toLowerCase() === "p"
                ) {
                    nextElement.classList.add("caption")
                }
            })
        }, [props.className])

        return (
            <>
                <style>{`.${props.className} img {
                     margin: 0 auto !important;                    
                }
                .${props.className} .caption,
                .${props.className} .caption a {
                    text-align: center !important;
                    font-size: ${
                        originalFontSize * CAPTION_SIZE_FACTOR
                    }px !important;
                }`}</style>
                <Component {...props} />
            </>
        )
    }
}
